
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

import dataMixin from '../mixins/data';
import uiMixin from '../mixins/ui';

const getRandomInt = function(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

const randomUnicodeString = (length) => {
  // pick a clean, foreign-looking unicode block.
  // https://www.compart.com/en/unicode/block/U+1400
  const sets = [
    [880, 1023],
    // this is http://www.alanwood.net/unicode/unified_canadian_aboriginal_syllabics.html
    [5120, 5750],
    [10176, 10223],
    [10624, 10751],
  ];
  const i = getRandomInt(0, sets.length);
  const a = Array.from({ length }, () => String.fromCharCode(getRandomInt(sets[i][0], sets[i][1] + 1)));
  return a.join('');
};

export default {
  name: 'DayStart',
  mixins: [
    dataMixin,
    uiMixin,
  ],
  data() {
    return {
      locationNameDisplay: '',
      slideVal: 0,
      isSlideEnabled: true,
    };
  },
  computed: {
    ...mapState('scene', [
      'didAnimateOnce',
    ]),
    ...mapGetters('gen', [
      'locationName',
      'sunColor',
    ]),
    ...mapGetters('userSettings', [
      'journeyStartedAtDay',
      'dayStartedAtDay',
    ]),
    isJourneyStartOnVirtualDay() {
      return this.journeyStartedAtDay && this.journeyStartedAtDay.isSameOrAfter(this.$store.getters.virtualDay);
    },
    isJourneyStartBeforeVirtualDay() {
      return this.journeyStartedAtDay && this.journeyStartedAtDay.isBefore(this.$store.getters.virtualDay);
    },
    isYesterdayValid() {
      return this.dayStartedAtDay &&
        (this.dayStartedAtDay.isSameOrAfter(this.$store.getters.virtualDay) ||
        this.$store.getters['actionsUser/valueTotalYesterday'] > 0);
    },
    isNeedingDayUpdate() {
      return !this.dayStartedAtDay || this.dayStartedAtDay.isBefore(this.$store.getters.virtualDay);
    },
    isJourneyDayValid() {
      return (!this.journeyStartedAtDay && !this.dayStartedAtDay) ||
        (this.journeyStartedAtDay && !this.dayStartedAtDay) ||
        // has both
        (this.dayStartedAtDay.isSameOrAfter(this.journeyStartedAtDay));
    },
    didEndStreak() {
      return this.isJourneyDayValid &&
        this.isJourneyStartBeforeVirtualDay &&
        !this.isYesterdayValid;
    },
    slideValProgress() {
      return this.slideVal / 100;
    },
    detailsStyle() {
      return {
        opacity: !this.isNeedingDayUpdate ? 1 : `${1 - this.slideValProgress}`,
      };
    },
    resourceStyle() {
      return {
        opacity: !this.isNeedingDayUpdate ? 1 : `${Math.min(1, 0.5 + this.slideValProgress)}`,
      };
    },
    statusResourceStyle() {
      if (this.slideValProgress === 1) {
        return {};
      }

      const scale = 1 - (this.slideValProgress * 0.6);

      return {
        borderRadius: `${50 - (this.slideValProgress * 50)}%`,
        transform: `translate(-50%, -50%) rotateZ(${this.slideValProgress * 225}deg) scale(${scale})`,
        opacity: this.slideValProgress,
      };
    },
    statusCountStyle() {
      const scale = 1 + (1 - Math.abs((this.slideValProgress - 0.5) * 2));

      return {
        transform: `rotateX(${this.slideValProgress * 180}deg) scale(${scale})`,
      };
    },
    daysCount() {
      return Math.max(0, this.isJourneyDayValid ? this.$store.getters['userSettings/daysCount'] : 0);
    },
    daysCountNext() {
      return this.didEndStreak ? 1 : this.daysCount + 1;
    },
    sunColorDisplay() {
      let str = '';

      if (Array.isArray(this.sunColor)) {
        str = this.sunColor.map(v => v.toPrecision(4)).join(' / ');
      }

      return str;
    },
  },
  watch: {
    locationName() {
      this.doDayNameAnimation();
    },
    slideVal() {
      if (this.isSlideEnabled && this.slideVal === 100) {
        this.isSlideEnabled = false;
        this.handleStart();
      }
    },
  },
  mounted() {
    this.doDayNameAnimation();
  },
  methods: {
    doDayNameAnimation() {
      if (window.Cypress || !this.isNeedingDayUpdate) {
        this.locationNameDisplay = this.locationName;
        return;
      }

      const indicesUnsettled = _.shuffle(Array.from(this.locationName).map((_char, i) => i));
      const indicesSettled = [];
      const iterations = this.locationName.length;
      let i = 0;

      const interval = setInterval(() => {
        if (i < iterations) {
          // pick a random index to mark as settled for the next iteration
          const iRand = getRandomInt(0, indicesUnsettled.length);
          indicesSettled.push(indicesUnsettled[iRand]);
          indicesUnsettled.splice(iRand, 1);

          const textRand = randomUnicodeString(this.locationName.length);
          this.locationNameDisplay = Array.from(this.locationName)
            .map((char, idx) => indicesSettled.includes(idx) ? char : textRand[idx]).join('');

          i += 1;
        } else {
          this.locationNameDisplay = this.locationName;
          clearInterval(interval);
        }
      }, 100);
    },
    handleSlideEnd() {
      if (this.isSlideEnabled && this.slideVal < 100) {
        this.slideVal = 0;
      }
    },
    handleStart() {
      // in case it was forced open
      this.$store.dispatch('ui/hideModal', 'dayStart');

      if (!this.isJourneyDayValid || this.didEndStreak || this.isNeedingDayUpdate) {
        const now = this.$dayjs();
        let journeyStartedAtDay = this.journeyStartedAtDay;

        if (!journeyStartedAtDay || this.didEndStreak) {
          journeyStartedAtDay = now.subtract(1, 'second');
        }

        if (this.isDebugger) {
          setTimeout(() => {
            this.$store.dispatch('resources/grantDayStart');
          }, 1000);
        }

        setTimeout(() => {
          this.$store.dispatch('userSettings/edit', {
            dayStartedAt: now.toDate(),
            journeyStartedAt: journeyStartedAtDay.toDate(),
          });

          this.$store.dispatch('logEvent', { name: 'day_start', params: { days: this.daysCountNext } });
        }, 1500);
      }
    },
  },
};
